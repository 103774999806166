*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* fonts */
/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cormorant:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&display=swap"); */

/* @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bebas+Neue&family=Cormorant:wght@300;400;500;600;700&display=swap'); */

/* @import url("https://fonts.googleapis.com/css2?family=Codystar:wght@300;400&family=Inter:wght@300;400;500;600;700;800&display=swap"); */

html,
body {
  font-size: 100%;

  font-family: var(--cormoFont);

  /* scroll-snap-type: y mandatory; */
  scroll-behavior: smooth;
}

/* Custom Scrollbar */

/* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--roseNT);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bookingColor);
}

body.fixedPosition {
  position: fixed;
  overflow: hidden;
}
h1,
h2,
h3 {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}

/*16px*/

:root {
  --roseNT: #d784a5;
  --bookingColor: #9a5e5e;
  --blueSession: #1c3144;
  --cormoFont: "Cormorant", "serif";
  /* --amaticFont: 'Amatic SC', 'cursive'; */

  --italianaFont: "Italiana", sans-serif;
  --textColor: black;
  --letterSpacing: 1px;
  --navHeight: 10vh;
  --alertFailed: red;
  --alertSuccess: green;
  --white: white;
  --padding-1rem: 1rem;
  --crimsonFont: "Crimson Pro", serif;
}

h1 {
  font-size: 3rem;
  line-height: 3.5rem;
}

h2 {
  font-size: 1.5rem;
}

body,
a {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--cormoFont);
  background: white;
  font-weight: 400;

  line-height: 1.75;
  color: var(--textColor);
  border: none;
}

a.button {
  /* border: 1px solid var(--bookingColor);
  padding: 1rem 2rem;

  cursor: pointer; */
  border: 1px solid var(--bookingColor);
  padding: 1rem 2rem;

  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background-color: var(--bookingColor);
    color: var(--white);
  }
}

button {
  cursor: pointer;
  opacity: 1;
}
button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

li {
  list-style: none;
}

li.navlink {
  cursor: pointer;
}

a {
  text-decoration: none;
  background: none;
  /* font-size: 1.6rem; */
}

p {
  font-size: 1rem;
}

button {
  background: none;
  border: 1px solid black;
  text-transform: uppercase;
  padding: 1rem;
}

/* .quote {

  font-family: var(--amaticFont);
} */

.fl {
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grecaptcha-badge {
  visibility: hidden;
}

.uppercase {
  text-transform: uppercase;
}

a.category {
  padding: 1rem;
}
.category {
  border: 1px solid var(--roseNT);
  font-family: var(--crimsonFont);
  color: var(--roseNT);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  /* &:hover {
    background-color: var(--roseNT);
    color: white;
  } */

  &.category-active {
    background-color: var(--roseNT);
    color: white;
  }
}

.list li:not(.special)::before {
  content: "✓";
  margin-right: 1rem;
}

.masInfo {
  font-size: 1rem;
  display: block;
  max-width: 400px;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  /* padding: 1rem;
  border: 1px solid red; */
}

@media (width>720px) {
  .category {
    &:hover {
      background-color: var(--roseNT);
      color: white;
    }
  }
}
@media (width>820px) {
  .fl {
    display: flex;
    flex-direction: row;
  }

  p {
    font-size: 1rem;
    line-height: 2rem;
  }
}

@media (width > 1025px) {
  ::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: block;
    box-shadow: inset 0 0 2px grey;
    border-radius: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--roseNT);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--bookingColor);
  }
}
@media (width> 1399.98px) {
  main h1 {
    font-size: 4rem;
    line-height: 6rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1.8rem;
  }
}
